import React from 'react';
import { useScrollTrigger, Fade, Box, useMediaQuery } from '@mui/material';
import { useRouter } from "next/router";

function ScrollTop(props) {
  const { children, window } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const router = useRouter();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 2000,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: router.pathname === '/product/[slug]' && isMobile ? 124 : 84, right: 28 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

export default ScrollTop;
