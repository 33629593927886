import { Barlow, Public_Sans } from "next/font/google";

export const primaryFont = Public_Sans({
  weight: ['400', '500', '600', '700', '800'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});

export const secondaryFont = Barlow({
  weight: ['900'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});
export const fontSize = 14;

export const typography = {
  fontSize,
  fontFamily: primaryFont.style.fontFamily,
  htmlFontSize: 16,
  body1: {
    fontSize,
  },
  body2: {
    fontSize,
  },
};
