import { components } from './components';
import { grey, primary, themeColors } from './themeColors';
import { typography } from './typography';
import { alpha } from "@mui/system";
export const THEMES = {
  GIFT: 'GIFT',
  HEALTH: 'HEALTH',
  DEFAULT: 'DEFAULT',
  GROCERY: 'GROCERY',
  FURNITURE: 'FURNITURE',
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

export const themesOptions = {
  typography,
  breakpoints,
  components: {
    ...components,
  },
  palette: {
    primary: {
      ...primary,
      light: primary[100],
    },
    ...themeColors,
    divider: alpha(grey[500], 0.24),
  },
};
