import { Fragment, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import MuiTheme from '../src/theme/MuiTheme';
import '../styles.css';
import { MediaContextProvider } from '@web/media';
import { AnalyticsProvider } from '../context/analytics';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import ScrollTop from '@web/components/ScrollTop';
import { Box, Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import ErrorBoundary from '@web/components/ErrorBoundary';
import { CollectionsProvider } from '@web/context/collections';
import { SearchProvider } from '@web/context/search';
import { DropdownProvider } from '@web/context/dropdown';
import { GoogleAnalytics } from '@next/third-parties/google';

const COOKIE_NAME = 'user_id';
const USER_ID_KEY = 'bw_user_id';
const App = ({ Component, pageProps }) => {
  const router = useRouter();
  const [cookie, setCookie] = useCookies([COOKIE_NAME]);
  const AnyComponent = Component;
  const getLayout = AnyComponent.getLayout ?? ((page) => page);

  useEffect(() => {
    // First, check if the user ID exists in localStorage
    let userId = localStorage.getItem(USER_ID_KEY);

    if (!userId) {
      // If the user ID does not exist in localStorage, check the cookie
      userId = cookie[COOKIE_NAME];

      if (userId) {
        // If the user ID exists in the cookie, save it to localStorage
        localStorage.setItem(USER_ID_KEY, userId);
      } else {
        userId = uuidv4();
        localStorage.setItem(USER_ID_KEY, userId);
      }
    }
  }, [cookie, setCookie]);

  return (
    <Fragment>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="BuyWisely is your one-stop destination for finding the best deals from leading online retailers in Australia. Our mission is to help you save time and money by providing accurate, up-to-date price comparisons on thousands of products across various categories."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <link
          rel="canonical"
          href={`https://buywisely.com.au${router.asPath}`}
        />
      </Head>
      <UserProvider>
        <MuiTheme>
          <MediaContextProvider disableDynamicMediaQueries>
            <CollectionsProvider>
              <SearchProvider>
                <AnalyticsProvider>
                  <DropdownProvider>
                    <ErrorBoundary>
                      <Box id="back-to-top-anchor" />
                      {getLayout(<AnyComponent {...pageProps} />)}
                      <ScrollTop>
                        <Fab size="small" aria-label="scroll back to top">
                          <KeyboardArrowUpIcon />
                        </Fab>
                      </ScrollTop>
                    </ErrorBoundary>
                  </DropdownProvider>
                </AnalyticsProvider>
              </SearchProvider>
            </CollectionsProvider>
          </MediaContextProvider>
        </MuiTheme>
      </UserProvider>
      <GoogleAnalytics gaId="G-M91Z4R0XFN" />
    </Fragment>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// App.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps };
// };

export default App;
