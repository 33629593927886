'use client';

import React, { useState, createContext, useContext } from 'react';

const DropdownContext = createContext<any>(undefined!);

export const DropdownProvider = ({ children }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <DropdownContext.Provider value={{ dropdownOpen, setDropdownOpen }}>
      {children}
    </DropdownContext.Provider>
  );
};

// Create a hook to use the context
export const useDropdownContext = () => {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error(
      'useDropdownContext must be used within a DropdownProvider'
    );
  }
  return context;
};
