'use client';

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import axios from 'axios';

// Create the context
const CollectionsContext = createContext<any>(undefined);

export const CollectionsProvider = ({ children }) => {
  const { user } = useUser();
  const [collections, setCollections] = useState<any>([]);

  // You can add logic here to fetch the user's collections when the component mounts
  useEffect(() => {
    if (user) {
      axios.get(`/api/collections`).then((res) => {
        setCollections(res.data);
      });
    }
  }, [user]);

  const addCollection = useCallback((newCollection) => {
    setCollections((prevCollections) => [...prevCollections, newCollection]);
  }, []);

  const removeCollection = (productId) => {
    setCollections((prevCollections) =>
      prevCollections.filter((collection) => collection.pid !== productId)
    );
  };

  return (
    <CollectionsContext.Provider
      value={{ collections, addCollection, removeCollection }}
    >
      {children}
    </CollectionsContext.Provider>
  );
};

export const useCollections = () => {
  return useContext(CollectionsContext);
};
